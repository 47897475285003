<template>
	<div>
		<div v-if="element == 'main-select' && shipmentEligibleForAutoDelivery">
			<div v-if="showMainSelect" class="shipto test">
				<span class="ship-to">SHIPMENT FREQUENCY</span>
				<div class="col-md-7">
					<div class="arrow">
						<select v-model="selectedFrequency" class="standard pad-left" aria-label="select auto delivery" @change="shipmentAutoDelivery">
							<option value="0">One Time</option>
							<option value="1">Every 4 Weeks</option>
							<option value="2">Every 8 Weeks (Most Popular)</option>
							<option value="3">Every 12 Weeks</option>
							<option value="4">Every 16 Weeks</option>
						</select>
					</div>
				</div>
			</div>
			<div v-else-if="showMainSelectMessage" class="shipto test">
				<span class="font-md">Your shipment is being Auto Delivered <b>every {{ parseInt(selectedFrequency)*4 }} weeks.</b></span>
				<div class="col-md-5">
					<button class="button button-secondary checkout-btn" @click="openLearnMoreModal">Edit Auto Delivery</button>
				</div>
			</div>
		</div>
		<div v-if="element == 'main-select' && !shipmentEligibleForAutoDelivery" class="p-t-20">
			<button class="button checkout-btn pull-right" @click="openLearnMoreModal">Add Auto Delivery</button>
		</div>
		
		<div v-if="element === 'cart-banner'">
			<div class="auto-delivery-banner" :style="shippingBannerStyle">
				<div v-if="selectedFrequencyCheck == '0'" class="auto-right">
					Get <strong>free shipping</strong> by adding <strong>Auto Delivery</strong> to your shipment. <a href="javascript:;" @click="openLearnMoreModal">Learn More</a>
				</div>
				<div v-else class="auto-right">
					Congratulations, you're now enjoying <span class="free-shipping">free shipping</span> with Auto Delivery
					<a class="mobile-ad-change" href="javascript:;">
						<b>every {{ selectedFrequency * 4 }} weeks.</b>&nbsp;
						<span class="color-tertiary font-sm no-wrap" @click="openLearnMoreModal">
							<i class="fa fa-pencil-square-o" aria-hidden="true" /> Change
						</span>
					</a>
				</div>
			</div>
		</div>

		<span v-if="element === 'line-item' && lineId && shipmentHasAutoDelivery">
			<span v-if="lineInAutoDelivery" key="inAutoDelivery" class="font-sm">
				Included in your Auto Delivery Shipment.  <a href="javascript:;" class="underline" @click="removeLine">Remove</a>
			</span>
			<span v-else key="notInAutoDelivery" class="font-sm">
				<b>One Time Delivery</b> <a href="javascript:;" @click="addLine"><span class="color-primary"><i class="fa fa-pencil-square-o" aria-hidden="true" /> Add to Auto Delivery </span></a>
			</span>
		</span>

		<div v-if="!isMobile && element === 'shipping-items' && page == 'shipping' && !shipmentHasAutoDelivery && shipmentEligibleForAutoDelivery">
			<div class="auto-delivery-banner" :class="element" :style="shippingBannerStyle">
				<div class="auto-right">
					Get <strong>free shipping</strong> by adding <strong>Auto Delivery</strong> to your shipment. <a href="javascript:;" @click="openLearnMoreModal">Learn More</a>
				</div>
			</div>
		</div>

		<div v-if="!isMobile && element === 'shipping-items' && page == 'shipping' && shipmentHasAutoDelivery ">
			<div class="auto-delivery-banner" :class="element" :style="shippingBannerStyle">
				<div class="auto-right">
					Congratulations, you're now enjoying <span class="free-shipping">free shipping</span> with Auto Delivery. <a href="javascript:;" @click="openLearnMoreModal">Learn More</a>
				</div>
			</div>
		</div>

		<div v-if="!isMobile && !isAutoDelivery && cartIsSingleShipment && element === 'cart-summary' && (page == 'shipping' || page == 'billing')" :class="{'m-t-15 border-top-2': element === 'cart-summary'}">
			<div class="auto-delivery-banner" :class="element" :style="shippingBannerStyle">
				<div class="auto-right">
					Get <strong>free shipping</strong> by adding <strong>Auto Delivery</strong> to your shipment. <a href="javascript:;" @click="openLearnMoreModal">Learn More</a>
				</div>
			</div>
		</div>

		<div v-if="!isMobile && isAutoDelivery && element === 'cart-summary' && (page == 'shipping' || page == 'billing')" :class="{'m-t-15 border-top-2': element === 'cart-summary'}">
			<div class="auto-delivery-banner" :class="element" :style="shippingBannerStyle">
				<div class="auto-right">
					Congratulations, you're now enjoying <span class="free-shipping">free shipping</span> with Auto Delivery
				</div>
			</div>
		</div>

		<AdInfoModal v-if="showLearnMore" key="ad-info" :modal-width="460" @close-modal="closeLearnMoreModal">
			<template #extra>
				<div v-if="shipmentEligibleForAutoDelivery">
					<div class="border-top-2 auto-delivery-info-header p-t-15">
						Set Auto Delivery frequency for your shipment
					</div>
					<div class="arrow">
						<select v-model="selectedFrequency" class="standard pad-left" aria-label="select auto delivery">
							<option value="0">One Time</option>
							<option value="1">Every 4 Weeks</option>
							<option value="2">Every 8 Weeks (Most Popular)</option>
							<option value="3">Every 12 Weeks</option>
							<option value="4">Every 16 Weeks</option>
						</select>
					</div>
					<button class="button full" style="margin-top: 10px" @click="shipmentAutoDelivery">CONFIRM AUTO DELIVERY FREQUENCY</button>
				</div>
				<div v-if="!shipmentEligibleForAutoDelivery">
					<div class=" border-top-2 auto-delivery-info-header p-t-15 color-primary">
						This shipment does not yet qualify for Auto Delivery.  Continue Shopping to find qualifying products if you’d like it to ship on a regular basis.
					</div>
					<button id="cancel" class="button full" @click="shopAutoDelivery">
						SHOP AUTO DELIVERY ITEMS
					</button>
				</div>
				<div class="modal-dismiss text-center p-t-15">
					<a href="javascript:;" @click="closeLearnMoreModal">NO THANKS</a>
				</div>
			</template>
		</AdInfoModal>

		<Modal v-if="showSignUp" key="ad-signup" modal-type="auto-delivery-sign-up" :modal-width="370" @close-modal="closeSignUpModal">
			<template #body>
				<div style="padding: 15px">
					<div class="auto-delivery-info-header sign-up" style="margin-bottom: 10px">SIGN UP</div>
					<p>Please provide a password to register and manage your Auto Delivery choices in the future</p>
					<br>
					<span v-if="guestValidationErrors.loginIssue" class="has-error">
						<p>{{ guestValidationErrors.loginIssue }}</p>
						<br>
					</span>
					<div class="form-group" :class="{ 'has-error': guestValidationErrors.email }">
						<label class="ad-sign-up">Email</label>
						<input v-model="guestEmail" type="text" class="form-control" autocomplete="off" @input="clearGuestRegisterValidation()">
						<div v-if="guestValidationErrors.emailMessage" style="display:inline-block" class="sg-input-advice">{{ guestValidationErrors.emailMessage }}</div>
					</div>
					<PasswordField 
						v-model="guestPassword"
						:show-requirements="true"
						@valid="guestPasswordValid = true"
						@invalid="guestPasswordValid = false"
					/>
					<br>
					<button id="cancel" class="button full" @click="registerGuest">Register Now</button>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import { emitter } from '../../main.js'
import { useCartStore } from '../../stores/CartStore'
import { useUserStore } from '../../stores/UserStore'
const axios = require('axios')
import checkoutHelpers from '../../mixins/checkout-global-functions.js'
import { mapState } from 'pinia'
import Modal from '../modals/modal'
import AdInfoModal from '../modals/ad-info-modal.vue'
import PasswordField from '../input/password-field'

export default {
	name: 'AutoDelivery',
	components: {
		AdInfoModal,
		Modal,
		PasswordField
	},
	mixins: [checkoutHelpers],
	props: {
		element: {
			type: String,
			required: true,
		},
		recipientId: {
			type: String,
			required: true
		},
		lineId: {
			type: String,
			required: false,
			default: undefined,
		}
	},
	data() {
		return {
			selectedFrequency: '0',
			showLearnMore: false,
			showEditAD: false,
			showSignUp: false,
			guestEmail: '',
			guestPassword: '',
			guestPasswordValid: false,
			page: '',
			isMobile: false,
			shipmentHasAutoDelivery: false,
			guestValidationErrors: {
				email: false,
				password: false,
				emailMessage: '',
				passwordMessage: ''
			}
		}
	},

	computed: {
		...mapState(useCartStore, ['isAutoDelivery']),
		...mapState(useUserStore,['getIsLoggedIn','getGuestEmail', 'getGuestPassword', 'getIsMobile']),
		shipmentEligibleForAutoDelivery() {
			return useCartStore().shipmentEligibleForAutoDelivery(this.recipientId)
		},
		lineInAutoDelivery() {
			return useCartStore().lineInAutoDeliveryShipment(this.lineId)
		},
		selectedFrequencyCheck() {
			return useCartStore().getAutoDeliveryFrequency(this.recipientId)
		},
		showMainSelect() {
			return this.isMobile || (!this.isMobile && this.selectedFrequencyCheck == '0')
		},
		showMainSelectMessage() {
			return !this.isMobile && this.selectedFrequencyCheck != '0'
		},
		shippingBannerStyle() {
			if(this.element == 'shipping-items') {
				return {
					'margin-left': '0px'
				}
			}

			return {}
		},
		cartIsSingleShipment() {
			return useCartStore().recipCount == 1
		}
	},
	created() {
		
	},
	mounted() {
		// What page are we on?
		this.page = window.location.href.replace(/\/$/, '').split("/").pop();

		this.cartStore = useCartStore()
		this.userStore = useUserStore()
		this.shipmentHasAutoDelivery = this.cartStore.shipmentHasAutoDelivery(this.recipientId)
		this.selectedFrequency = this.cartStore.getAutoDeliveryFrequency(this.recipientId)

		this.guestEmail = this.getGuestEmail

		this.isMobile = this.cartStore.getIsMobile

		// this.checkLearnMoreModalAutoLaunch()
	
		emitter.on('shipment-auto-delivery-updated', (evt) => {
			// Update selected frequency here.
			if(this.recipientId == evt.crec_id) {
				this.shipmentHasAutoDelivery = this.cartStore.shipmentHasAutoDelivery(evt.crec_id)
				this.selectedFrequency = evt.selectedFrequency

				if(this.element == 'cart-summary' || this.element == 'shipping-items') {
					this.selectedFrequency = evt.selectedFrequency
				}
			}
			
			this.hideLoadingOverlay()
		})
	
		emitter.on('line-auto-delivery-updated', (evt) => {
			if(evt && typeof(evt.lineId) != 'undefined' && evt.lineId == this.lineId && !evt.lineStillHasAutoDelivery) {
				this.selectedFrequency = '0'
			}

			this.hideLoadingOverlay()
		})

		emitter.on('guest-login-for-auto-delivery-complete', (evt) => {
			switch(evt.action) {
			case 'login':
				this.fireRecordCheckoutDecisionEvent('shippingStep', 'checkout-logged-in')
				break
			case 'new-registration':
				this.fireRecordCheckoutDecisionEvent('shippingStep', 'checkout-new-registration')
				break
			}

			this.showSignUp = false
			this.hideLoadingOverlay()
		})
	},
	methods: {
		openLearnMoreModal() {
			this.showLearnMore = true
		},
		closeLearnMoreModal() {
			this.showLearnMore = false
		},
		shopAutoDelivery() {
			window.location.href = '/top-items-for-auto-delivery'
		},
		updateADFrequency() {
			this.shipmentAutoDelivery()
			this.showEditAD = false
		},
		closeSignUpModal() {
			this.showSignUp = false
		},
		shipmentAutoDelivery() {
			this.closeLearnMoreModal()
			this.showLoadingOverlay()
			let tmpRecipId = this.recipientId
			if((this.element === 'cart-summary' || this.element === 'shipping-items') && this.cartIsSingleShipment) {
				tmpRecipId = this.cartStore.cart.recipients[0].crec_id
			}

			if(
				this.selectedFrequency != '0'
				&&
				!this.getIsLoggedIn
				&&
				this.getGuestPassword == ''
				&&
				(this.page == 'shipping' || this.page == 'billing')
			) {
				this.hideLoadingOverlay()
				this.showSignUp = true
			} else {
				this.cartStore.shipmentAutoDelivery(tmpRecipId, this.selectedFrequency)
			}
		},
		addLine() {
			this.showLoadingOverlay()
			this.cartStore.lineAutoDelivery(this.lineId, this.selectedFrequency)
		},
		removeLine() {
			if(this.cartStore.canRemoveItemFromAutoDelivery(this.lineId)) {
				this.showLoadingOverlay()
				this.cartStore.lineAutoDelivery(this.lineId, '0')
			} else {
				alert("By removing this item, your shipment will no longer qualify for Auto Delivery. To remove Auto Delivery enirely from this shipment, please select 'One Time' from the 'Shipment Frequency' select box.")
			}
		},
		registerGuest() {
			this.clearGuestRegisterValidation()
			this.showLoadingOverlay()
			if(!this.guestEmail) {
				this.guestValidationErrors.email = true
				this.guestValidationErrors.emailMessage = "A valid email is required"
			}

			if(this.guestValidationErrors.email || !this.guestPasswordValid) {
				this.hideLoadingOverlay()
				return
			}

			this.showLoadingOverlay()
			let guestData = {
				email: this.guestEmail,
				password: this.guestPassword
			}
			this.userStore.autoDeliverySpecialRegister(guestData)
				.then((response) => {
					if(response.status == 'error') {
						this.guestValidationErrors.email = true
						this.guestValidationErrors.password = true
						this.guestValidationErrors.loginIssue = "Unable to login or register.  Please try again."
					} else {
						this.cartStore.shipmentAutoDelivery(this.cartStore.cart.recipients[0].crec_id, this.selectedFrequency)
						emitter.emit('guest-login-for-auto-delivery-complete', {'action': 'new-registration'})
						window.location.reload()
					}
				})
				.finally(() => {
					this.hideLoadingOverlay()
				})
		},
		clearGuestRegisterValidation() {
			this.guestValidationErrors = {
				email: false,
				password: false,
				loginIssue: false,
				emailMessage: '',
				passwordMessage: ''
			}
		},
		checkLearnMoreModalAutoLaunch() {
			if(
				!this.isMobile
				&& this.page == 'cart'
				&& this.element == 'main-select'
				&& this.cartIsSingleShipment
				&& this.cartStore.cart.recipients[0].is_myself == '1'
				&& !this.isAutoDelivery
			) {
				setTimeout(() =>  {
					this.openLearnMoreModal()
				}, 1000)
			}
		},	
		closeFrequencyModal() {
			this.finalSelectedFrequency = this.selectedFrequency
			this.line.is_perpetual_subscription = (this.selectedFrequency > 0) ? true : false
			// this sets up the subscription objects incase they aren't there
			this.line.subscription_interval = {}
			this.line.detail.perpetual_subscription = {}
			this.line.subscription_interval.interval_months = this.selectedFrequency
			this.line.detail.perpetual_subscription.interval_months = this.selectedFrequency
			// this is getting updated in the cartStore because we are updating the this.line - vue3 magic
			this.showFrequencyModal = false
			// this.cartStore.isPerpetualSubscription()
			// Notify the world
			emitter.emit('auto-delivery-frequency-change', {ADSelected: this.selectedFrequency > 0})
			// notify the server
			axios.put('/api/checkout/line-auto-delivery', { clin_id: this.line.clin_id, selected_frequency: this.selectedFrequency })
				.then((response) => {
					// console.log('auto deli response:', response)
				})
				.catch(error => {
					this.$sentry.captureException(error)
				})
		}
	}
}
</script>

<style scoped>
	a.mobile-ad-change {
		color: #605255;
		display: none;
		font-weight: normal;
		font-family: "Roboto Condensed", sans-serif;
	}
	@media ( max-width: 768px) {
		a.mobile-ad-change {
			display: inline-block;
		}
	}
	a.underline {
		border-bottom: 1px solid !important;
	}
	.free-shipping {
		font-weight: bold;
		color: #498722;
	}
	.modal-body {
		padding-top: 0px !important;
	}
	.test {
		text-align: right !important;
	}
	.auto-delivery-info-header.sign-up {
		font-size: 20px;
		font-weight: 300;
		font-family: 'Roboto Condensed', sans-serif;;
	}
	.auto-delivery-banner.shipping-items {
		font-size: 16px;
		text-align: left;
	}
	label.ad-sign-up {
		font-size: 16px;
		text-transform: uppercase;
	}
	.password-requirements {
		font-size: 12px
	}
	.password-requirements-list ul li:before {
		content: '';
	}
	.password-requirements-list ul li {
		font-size: 12px !important;
		list-style-type: disc !important;
		padding-left: 15px;
		margin-left: 15px;
		margin-bottom: 0;
		line-height: 20px;
	}
	.password-validation-error {
		color: #AD0B21;

	}
	.sub-total-container .ship_to_modal {
		text-align: left;
	}
	.has-error p,
	.has-error .help-block,
	.has-error .control-label,
	.has-error .radio,
	.has-error .checkbox,
	.has-error .radio-inline,
	.has-error .checkbox-inline,
	.has-error.radio label,
	.has-error.checkbox label,
	.has-error.radio-inline label,
	.has-error.checkbox-inline label {
		color: #AD0B21 !important;
	}
	.has-error .form-control {
		border-color: #AD0B21 !important;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
	}
	.has-error .form-control:focus {
		border-color: #843534 !important;
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
	}
	.has-error .input-group-addon {
		color: #AD0B21 !important;
		border-color: #AD0B21 !important;
		background-color: #f2dede !important;
	}
	.has-error .sg-input-advice {
		color: #AD0B21 !important;
	}
</style>